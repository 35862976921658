.center {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.next-button {
  justify-content: end; // Align items to the right
  align-items: end;
  align-self: flex-end;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.yes {
  border: 0;
  border-radius: 20px;
  height: 40px;
  width: 100px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 20px;
  padding: 5px 15px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  background-color: green;
}
.yes-disabled {
  border: 0;
  border-radius: 20px;
  height: 40px;
  width: 100px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 20px;
  padding: 5px 15px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  background-color: rgb(88, 88, 88);
}
