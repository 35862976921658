.card {
  max-width: 20rem;
  width: 90%;
  background-color: #a6a6a6;
  margin: 2rem 0rem;
  border-radius: 15px;
  transition: 0.3s;
  padding: 20px 25px;
}

.card span {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

p.huh {
  margin: 8px 0;
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  color: black;
}

.center {
  display: flex;
  text-align: center;
  justify-content: center;
}

.card input {
  outline: 0;
  background: #cfcfcf;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Comfortaa", cursive;
}

.price {
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  padding: 10px 0px;
  margin: 10px 0px;
}
.button-array {
  margin: 1.5rem 0rem;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 3rem;

  .trip-intransit {
    flex-direction: column;
    button {
      margin-top: 20px;
    }
  }
}

@media (max-width: 325px) {
  .button-array {
    flex-direction: column;
  }
}

.button-array button {
  border: 0;
  border-radius: 20px;
  height: 40px;
  width: 90px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 20px;
  padding: 5px 15px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.yes {
  background-color: green;
}

.no {
  background-color: red;
}

.nego {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.nego button {
  background-color: rgb(36, 86, 248);
}
