.App {
  text-align: center;
  font-family: "Comfortaa", cursive;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link-style {
  text-decoration: none;
}

.centered-button {
  font-family: "Comfortaa", cursive;
  outline: 0;
  background: #008000;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.centered-button:active {
  background: #006e00;
}

.centered-button:disabled {
  background: #69ab69;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

.centered-image {
  margin-top: 20vh;
  width: 90vw;
  max-width: 300px;
  height: auto;
}

.centered-button {
  max-width: 350px;
  min-width: 200px;
  height: 50px;
  margin-top: 5vh;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
}

.footerLink {
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
}

.footerLink:hover {
  text-decoration: underline;
}

.choose-user {
  list-style-type: none;
  margin: 0px 0px 10px 0px;
  padding: 0;
}

.choose-user {
  display: flex;
  justify-content: space-between;
}

.choose-user li {
  float: left;
  margin: 0 5px 0 0;
  width: 45%;
  height: 40px;
  position: relative;
}

.choose-user label,
.choose-user input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.choose-user input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.choose-user input[type="radio"] + label {
  font-family: "Comfortaa", cursive;
  outline: 0;
  background: #777777;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.choose-user input[type="radio"]:checked + label {
  background: #3a3a3a;
}

.choose-user label {
  padding: 5px;
  cursor: pointer;
  z-index: 0.9;
}

.choose-user input[type="radio"]:hover:not(:checked) + label {
  background: #616161;
}
.submit {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

input[type="file"] {
  display: none;
  width: 100%;
  margin-top: 10px;
}

.file_input {
  display: inline-block;
  color: white;
  background: #616161;
  text-align: center;
  padding: 15px 20px;
  font-size: 12px;
  letter-spacing: 1.5px;
  user-select: none;
  cursor: pointer;
  box-shadow: 5px 15px 25px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.attachment {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 31px;
  height: 25px;
  animation: spin 2s linear infinite;
}

.loader2 {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.waiting-spin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .wait {
    font-size: 22px;
    color: #000000;
    margin: 0px;
    margin-top: 20px;
    display: block;
  }
}

.padding {
  margin-bottom: 200px;
}

.dotw {
  font-size: 25px;
  background-color: #ece75f;
  border-radius: 20px;
  border: 1px solid #000000;
  width: fit-content;
  padding: 10px 15px;
  margin: 15px auto;
}

.call-options {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;
  font-size: 25px;
  background-color: #ece75f;
  border-radius: 20px;
  border: 1px solid #000000;
  padding: 10px 15px;
  img {
    width: 50px;
    height: 50px;
  
  }
}

.phone-num {
  padding: 0;
  width: 100%;
  align-items: left;
  .iti.iti--allow-dropdown.iti--show-selected-dial-code.iti--show-flags {
    width: 100%;
  }
}

.card {
  width: 100%;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.spinner .path {
  fill: none;
  stroke: hsl(210, 70%, 75%);
  stroke-width: 6;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
