@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

body {
  background: linear-gradient(90deg, #ece75f 0%, #ece75f 100%);
  font-family: "Comfortaa", sans-serif;
  margin: 0;
}

#root {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-logo {
  display: grid;
  justify-self: center;
  align-self: center;
  width: 100px;
  height: 100px;
  margin: 15px;
}

.login,
.register,
.driver,
.basic {
  // min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 90vw;
  max-width: 400px;
  margin: auto;
  margin-bottom: 20px;
  font-family: "Comfortaa", sans-serif;
}

.login {
  height: 80vh;
  align-items: center;
}

.driver {
  height: 80vh;
  align-items: center;

  // .form button {
  //   margin-top: 80px;
  // }
}

.form {
  width: 100%;
  max-width: 400px;
  border-radius: 50px;
  background-color: #f7f5bc;
  border-left: 15px solid #e47200;
  border-right: 15px solid #e47200;
  border-top: 10px solid #e47200;
  border-bottom: 10px solid #e47200;
  // margin: 20px auto;
  padding: 30px 40px 80px;
  text-align: center;
}

.basic-body {
  position: relative;
  z-index: 1;
  border-radius: 50px;
  border-left: 15px solid #e47200;
  border-right: 15px solid #e47200;
  border-top: 10px solid #e47200;
  border-bottom: 10px solid #e47200;
  // max-width: 360px;
  // margin: 0 auto 100px;
  margin: 0 auto;
  width: 80%;
  padding: 45px 25px;
  text-align: center;
  padding: 10px 25px;
  background-color: #f7f5bc;
}

.found {
  font-size: 14px;
  text-align: left;
  padding: 0 25px;
  margin: 40px 0px;
  mark {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 50px;
  }
}

.profile {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  margin-bottom: 10px;
}

.price {
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  padding: 10px 0px;
}

.button-array {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
}

.button-array button {
  border: 0;
  border-radius: 20px;
  height: fit-content;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 15px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.yes {
  background-color: green;
}

.no {
  background-color: red;
}

.nego {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.nego button {
  background-color: rgb(36, 86, 248);
}

.form input,
.form select {
  outline: 0;
  background: #cfcfcf;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Comfortaa", cursive;
}

.form input:focus {
  background: #ebebeb;
}

.form button {
  font-family: "Comfortaa", cursive;
  outline: 0;
  background: #008000;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.sub button {
  font-family: "Comfortaa", cursive;
  outline: 0;
  background: #777777;
  width: 45%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.sub {
  display: flex;
  justify-content: space-between;
}

.form button:active {
  background: #006e00;
}

.form span.page-title {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
  display: block;
}

span.about {
  font-size: 15px;
  color: #000000;
  margin-bottom: 30px;
  display: block;
}

div.userName {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  p {
    font-size: 22px;
    background-color: #ece75f;
    border-radius: 20px;
    font-weight: bold;
    width: fit-content;
    padding: 10px 15px;
  }
}

.form p.error {
  margin: 5px 0 15px 10px;
  text-align: left;
  font-size: 10px;
  color: red;
}

.form p.huh {
  margin-bottom: 5px;
  margin-top: 0px;
  font-weight: bold;
  text-align: left;
  font-size: 13px;
  color: black;
}

.prefer-private {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  // text-align: left;
  .check-input {
    border: 0px;
    height: 1.5em;
    width: 1.5em;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    &:checked {
      accent-color: green;
    }
  }
  
}

.background {
  position: relative;
  z-index: 1;
  background: #f7f5bc;
  border-radius: 50px;
  border: 10px solid #e47200;
  border-left-width: 15px;
  border-right-width: 15px;
  width: 80vw;
  max-width: 400px;
  // margin: 0 auto;
  padding: 35px 25px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.button {
  font-family: "Comfortaa", cursive;
  outline: 0;
  background: #008000;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &-check-in {
    width: fit-content;
  }

  &-proceed {
    width: fit-content;
    align-self: flex-end;
  }
}

.username {
  font-size: 22px;
  background-color: #ece75f;
  border-radius: 20px;
  width: fit-content;
  padding: 10px 15px;
}

.input {
  outline: 0;
  background: #cfcfcf;
  width: 100vw;
  border: 0;
  border-radius: 5px;
  margin: 7.5px 0;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Comfortaa", cursive;

  &-medium {
    width: 60vw;
  }
}

.text-details-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 30px;
  text-align: left;
}

.small-text {
  color: #000000;
  font-size: 12px;
}

.orders {
  font-size: 22px;
  background-color: #e9e582;
  border-radius: 20px;
  width: 100%;
  padding: 10px 15px;
}

.header {
  color: #ffffff;
  background: #373d48;
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 3px;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
  padding: 15px 10px;
  justify-content: space-around;

  &-text {
    text-align: left;
    color: #ffffff;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;

  > div:nth-child(odd) {
    background: #d1d1da;
  }

  > div:nth-child(even) {
    background: #b0bdc1;
  }
}

.row {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
  padding: 15px 10px;
  margin-bottom: 2px;

  &-cell {
    display: flex;
    align-items: center;
    max-width: 20vw;
  }
}

.private_p {
  color: #FF0000;
  margin-left: 5px;
  font-size: 10px;

}

.price-dropdown {
  background-color: #007100;
  color: #ececec;
  text-align: center;
  width: 75%;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  font-size: 13px;
  font-family: "Comfortaa", cursive;
}

.radio-input {
  border: 0px;
  width: 100%;
  height: 2.2em;

  &:checked {
    accent-color: green;
  }
}

.amount-container {
  display: flex;
  flex-direction: row;
  overflow: wrap;
  max-width: 20vw;
}

.amount {
  font-size: 8px;
  padding: 2px 4px;
  background-color: #006e00;
  border-radius: 2px;
  margin: 5px;
}

.consent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: left;
}

.consent input[type="checkbox"] {
  width: 25px;
}

.webview-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  text-align: center;
  color: red;
}
